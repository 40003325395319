import React, { Component } from 'react';
import { Menu } from 'semantic-ui-react'

const shown_width = 3

export default class Pagination extends Component {

  range() {
    let { pages, current_page } = this.props

    let lower = current_page - shown_width
    let upper = current_page + shown_width
    let move_by = 0

    if (lower < 1)
      move_by = 1 - lower
    if (upper > pages)
      move_by = move_by = pages - upper
    lower += move_by
    upper += move_by
    return { lower, upper }
  }

  first() {
    let { current_page } = this.props
    let { onClick } = this.props
    let ret = [<Menu.Item active={current_page === 1} key="before" onClick={() => onClick(1)}>1</Menu.Item>]

    if (this.range().lower > 1)
      ret.push(<Menu.Item key="before_ellipse">...</Menu.Item>)
    return ret
  }

  middle() {
    let { pages, current_page, onClick } = this.props
    let ret = []
    let { lower, upper } = this.range()
    for (let i = lower + 1; i < upper; i = i + 1)
      ret.push(i)
    ret = ret.filter(a => a > 1 && a < pages)
    ret = ret.map(a => (<Menu.Item active={current_page === a} key={a} onClick={() => onClick(a)}>{a}</Menu.Item>))

    return ret
  }

  last() {
    let range = this.range()

    // Do not display if element overlap lower range
    if (range.upper <= 1)
      return []

    let { pages, current_page, onClick } = this.props
    let ret = [<Menu.Item key="after" active={current_page === pages} onClick={() => onClick(pages)}>{pages}</Menu.Item>]

    if (range.upper < pages)
      ret.unshift(<Menu.Item key="after_ellipse">...</Menu.Item>)
    return ret
  }

  render() {
    return (
      // Color Teal makes the selected text more distinguishable
      <Menu color="teal" pagination>
        {this.first()}
        {this.middle()}
        {this.last()}
      </Menu>
    );
  }
}
