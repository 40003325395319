import React, {useContext} from 'react'
import { StoreContext } from './StoreContext'
import { Header } from 'semantic-ui-react'
import Container from './Container'

export let StoreContainer = props=>{
    let [value] = useContext(StoreContext)
    let {selectedDealer, selectedStore, languages, programs, setLoadStores} = value

    if (!selectedStore || !selectedDealer) return null

    return <>
        <Header>{selectedDealer.name} {selectedStore.language}</Header>
        <Container
            store={selectedStore}
            dealer={selectedDealer}
            languages={languages}
            programs={programs}
            setLoadStores={setLoadStores}
        />
    </>
}
