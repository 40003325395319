import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Button, Container } from 'semantic-ui-react'

import Login from './Login'
import Home from './container/Home'
import CodeContainer from './container/CodeContainer'
import CustomerContainer from './container/CustomerContainer'
import DealerContainer from './container/DealerContainer'
import LanguageContainer from './container/LanguageContainer'
import OrderContainer from './container/OrderContainer'
import ProgramContainer from './container/ProgramContainer'
import StoreGroup, {StoreContext, StoreContainer} from './container/StoreGroup'
import LoginContext from './LoginContext'

const active = (path, route) => path === route ? 'teal' : undefined
const activeWithChild = (path, route) => path.startsWith(route) ? 'teal' : undefined

let PmMenu = props => {
    let { location: { pathname: path } } = props

    let [login, setLogin] = useState(undefined)
    let storeState = useState({dealer: null})

    // TODO this created an error when logging in.  I don't know why
    // Display login form if not logged in
    if (login === undefined) return <Login setLogin={setLogin} />

    const logout = () => {
        setLogin(undefined)
    }

    let is_admin = login.user.role === 'admin'
    let is_dealer = is_admin || login.user.role === 'dealer'

    return <LoginContext.Provider value={{ login }}>
        <nav>
            <Button as={Link} to='/' color={active(path, '/')}> Home </Button>
            <Button as={Link} to='/code' color={active(path, '/code')}> Code </Button>
            <Button as={Link} to='/customer' color={active(path, '/customer')}> Customer </Button>
            <Button as={Link} to='/order' color={active(path, '/order')}> Order </Button>
            {is_admin ? <Button as={Link} to='/program' color={active(path, '/program')} > Program </Button> : ''}
            {is_dealer ? <Button as={Link} to='/dealer' color={active(path, '/dealer')} >  Dealer </Button> : ''}
            {is_admin ? <Button as={Link} to='/language' color={active(path, '/language')} > Language </Button> : ''}
            {is_dealer ? <Button as={Link} to='/store' color={activeWithChild(path, '/store')} > Store </Button> : ''}
            <Button onClick={logout} color='black' basic> Logout { login.user.username} </Button>
            <Button color='black' disabled basic> { login.user.role} </Button>
        </nav>
        <main>
            <Route path="/" exact component={Home} />
            <Route path="/code" exact component={CodeContainer} />
            <Route path="/dealer" exact component={DealerContainer} />
            <Route path="/language" exact component={LanguageContainer} />
            <Route path="/customer" exact component={CustomerContainer} />
            <StoreContext.Provider value={storeState}>
                <Route path="/store" component={StoreGroup} />
                <Route path="/store/:store_id" component={StoreContainer} />
            </StoreContext.Provider>
            <Route path="/order" exact component={OrderContainer} />
            <Route path="/program" exact component={ProgramContainer} />
        </main>
    </LoginContext.Provider>
}

// See https://stackoverflow.com/questions/43895805/react-router-4-does-not-update-view-on-link-but-does-on-refresh
// for withRouter explaination
PmMenu = withRouter(PmMenu)


const App = props => {
    return (
        <Container>
            <Router>
            <PmMenu />
            </Router>
        </Container>
    );
}

export default App
