
export const display_code_form = ({ onClose }) => ({
  header: {
    icon: 'qrcode',
    content: 'Code'
  },
  inputs: [
    { name: "Program", value: '', property: 'program' },
    { name: "Demo Days", value: 30, property: 'demo_day' },
    { name: "Language", value: '', property: 'language' },
    { name: "Registrations", value: 2, property: 'registration' },
    { name: "Resets", value: 0, property: 'reset' },
    { name: "Site License", value: false, property: 'site_license', type: 'checkbox' }, // checkbox
    { name: "Notes", value: '', property: 'note', type: 'text' }
  ],
  // newButton: false,
  onClose: () => onClose(),
})
