import { realm_call} from './http'

/**
 *
 * @param results The result that will be saved
 * @param edit_item The item before saving
 * @param parent The Item this on is embedded in
 * @param the name of the mogoose model
 * @param state Not sure, but used by post
 * @param validator function which returns an error of eerror message
 * @param setError UseState setter for error messages
 * @param setSuccesses UseState setter for success messages
 * @param url The base url for this model
 * @param all Function to call when reloading data
 */
export const save_embedded_boilerplate = async ({
    token,
    results,
    edit_item,
    parent,
    model_name,
    state,
    validate,
    setErrors,
    setSuccesses,
    url,
    all,
}) => {
    // Validate form
    let error_messages = validate(results)
    // Display errors if form is invalid
    if (error_messages.length !== 0) {
        setErrors(error_messages)
        return
    }

    // alert(JSON.stringify({parent, edit_item},0,2))

    // Remove id so it does not get passed through
    // Id is needed for creating, but not editing
    let {id: _1, _id: _2, ...params} = results

    let response = (edit_item && edit_item._id) ?
    // update item
    await realm_call({
        token,
        function: "update",
        args: [ "users", parent._id, edit_item._id, params]
    })
    :
    // create item
    await realm_call({
        token,
        function: "create",
        args: [ "users", parent._id, results]
    })

  // if (response.status >= 400)
  //   throw new Error(response.statusText)

  // let json = await response.json()
  // let message

    if (response.error) {
        console.log("Error")
        setErrors([response.error])
    } else {
        console.log("Success")
        setSuccesses(["Saved"])
        if (all) {
            await all()
        }
    }
}

/**
 *
 * @param token The authorization token
 * @param results The result that will be saved
 * @param edit_item The item before saving
 * @param edit_item_prefix An optional prefix added when saving.  Needed for saving a store
 * @param the name of the mogoose model
 * @param state Not sure, but used by post
 * @param validator function which returns an error of eerror message
 * @param setError UseState setter for error messages
 * @param setSuccesses UseState setter for success messages
 * @param url The base url for this model
 * @param all Function to call when reloading data
 */
export const save_boilerplate = async ({
    token,
    results,
    edit_item,
    edit_item_prefix,
    model_name,
    state,
    validate,
    setErrors,
    setSuccesses,
    url,
    all,
}) => {
    // Validate form
    let error_messages = validate(results)
    // Display errors if form is invalid
    if (error_messages.length !== 0) {
        setErrors(error_messages)
        return
    }

    // Remove id so it does not get passed through
    // Id is needed for creating, but not editing
    let {id: _1, _id: _2, ...params} = results
    let subcommand = url

    // {
    //   let function_name = edit_item ? "update" : "create"
    //   let id = edit_item ? edit_item._id : ''
    //   alert(`Function = ${function_name}\nSubCommand=${subcommand}\nId=${id}\nParams = ${JSON.stringify(params,0,2)}`)  
    // }

    let response = edit_item ?
        // update item
        await realm_call({
            token,
            function: "update",
            args: [ subcommand, edit_item._id, params]
        })
    :
    // create item
    await realm_call({
        token,
        function: "create",
        args: [ subcommand, results]
    })

    if (response.error) {
        console.log("Error")
        setErrors([response.error])
    } else {
        console.log("Success")
        setSuccesses(["Saved"])
        if (all) {
            await all()
        }
    }
    return
}