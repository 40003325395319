import React, { useState, useEffect, useContext } from 'react';

import TableComponent from '../../component/TableComponent'
import { save_boilerplate } from '../../lib/common'
import { realm_call } from '../../lib/http'

import { code_form, validate } from './Code'
import { customer_form, customer_format } from'../../lib/customer'
import { event_form } from './Event'
import { count_events } from '../../lib/util.js'

import LoginContext from '../../LoginContext';
import Loader from 'react-loader-advanced';

export let CODE_MODAL = Symbol("CODE_MODAL")
export let CUSTOMER_MODAL = Symbol("CUSTOMER_MODAL")
export let EVENT_MODAL = Symbol("EVENT_MODAL")


let reset_format = item => {
  // print nothing if no resets have been set
  if (item.reset === 0)
    return ''

  // Count the number of reset events
  let resets = count_events(item.events, 'Reset')

  return `${resets}/${item.reset}`
}

let registration_format = item => {
  let events = item.events
  let regs = count_events(events, 'Register')
  let unregs = count_events(events, 'Unregister')

  return `${regs - unregs}/${item.registration}`
}

let select_modal = ({ modal, close, saveCode, user, languages, programs }) => {
  if (modal === CUSTOMER_MODAL) return customer_form({ onClose: close })
  if (modal === EVENT_MODAL) return event_form({ onClose: close })
  if (modal === CODE_MODAL) return code_form({
    user,
    onClose: close,
    showSave: true,
    onSave: saveCode,
    dropdowns: {
      languages: languages.map(language => language._id),
      programs: programs.map(program => program._id)
    },
  })
}

export default props => {
  let [busy, setBusy] = useState(false)
  let [codes, setCodes] = useState([])
  let [error_messages, setErrors] = useState([])
  let [success_messages, setSuccesses] = useState([])
  let [modal, setModal] = useState(CODE_MODAL)
  let [programs, setPrograms] = useState([])
  let [languages, setLanguages] = useState([])
  // Pagination
  let [pages, setPages] = useState(1)
  let [current_page, setCurrentPage] = useState(1)
  let [per_page, /* setPerPage */] = useState(10)
  let [search, setSearch] = useState('')
  let { token } = useContext(LoginContext).login

  // fetch the list of program
  useEffect(() => {
    let fetchPrograms = async () => {
      let  json = await realm_call({ token, function: "read", args: [ "programs"] })

      setPrograms(json.programs)
    }
    fetchPrograms()
    // eslint-disable-next-line 
  }, []);
  // fetch the list of languages
  useEffect(() => {
    let fetchLanguages = async () => {
      let  json = await realm_call({ token, function: "read", args: [ "languages"] })

      console.log("Languages", json.languages)
      setLanguages(json.languages)
    }
    fetchLanguages()
    // eslint-disable-next-line 
  }, []);
  // init Codes
  async function fetchCodes () {
    setBusy(true)
    let  json = await realm_call({ token, function: "read", args: [ "codes", {current_page, per_page, search, with: "customer"} ] })

    setCodes(json.codes)
    setPages(json.count)
    setBusy(false)
  }
  useEffect(() => {
    fetchCodes()
    // Disable warnings since it wants a dependacy on fetchCodes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    fetchCodes()
    // Disable warnings since it wants a dependacy on fetchCodes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languages, programs, current_page, per_page, search])

  let { user } = useContext(LoginContext).login

  let close = () => {
    // Reset values to their default
    setModal(CODE_MODAL);
    setErrors([]);
    setSuccesses([])
  }
  let saveCode = async (results, edit_item) => {
    // Inject the user into the request.
    results.user = user
    return save_boilerplate({
      token,
      results,
      edit_item,
      setErrors,
      setSuccesses,
      state: true,
      all: fetchCodes,
      validate,
      model_name: 'code',
      url: 'codes',
    })
  }

  // Make sure the form is ready to display and has all the needed information
  if (languages.length===0 || programs.length===0)
    return null

  let active_form = select_modal({ user, modal, languages, programs, close, saveCode })

  return (
    <Loader show={busy} message={'loading'}>

      <TableComponent
        title="Code"
        header={['Date', 'Code', 'Program', 'Regs', 'Site', 'Demo Days', 'Resets','Events', 'Customers']}
        body={codes}
        error_messages={error_messages}
        success_messages={success_messages}
        body_format={[
          { type: 'date', date: 'created_at', },
          { type: 'link:modal', url: 'code', },
          { type: 'custom', format: item => item.program },
          { type: 'custom', format: item => registration_format(item) },
          { type: 'boolean', value: 'site_license' },
          { type: 'custom', format: code=>code.demo_day!==0 ? code.demo_day : "" },
          { type: 'custom', format: item => reset_format(item) },
          { 
            type: 'array',
            // no array mean use the item itself
            // array: 'events',
            format: code => {
              return code.events.length
            },
            visible: code => code.events.length!==0,
            onClick: (code, display_modal) => {
              setModal(EVENT_MODAL)
              select_modal({ user, modal: EVENT_MODAL, close })
              display_modal(code.events)
            }
          },
          {
            type: 'array',
            array: 'customers',
            format: customer => customer_format(customer),
            onClick: (customer, display_modal) => {
              setModal(CUSTOMER_MODAL)
              select_modal({ user, modal: CUSTOMER_MODAL, close })
              display_modal(customer)
            }
          },              
        ]}

        onSearch={filter => { setCurrentPage(1); setSearch(filter) } }
        paginate={{
          pages,
          current_page,
          onClick: p => setCurrentPage(p),
        }}

        form={active_form}
      />
    </Loader>

  )
}


