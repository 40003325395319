
/**
 * Validates user data before saving
 *
 * @param {*} properties The data to validate
 */

export const validate_user = properties => {
  let ret = []

  if (properties._id.length===0)
      ret.push("Name can not be empty")

  if (properties.email.length===0)
      ret.push("Email can not be empty")

  if (properties.format.length===0)
      ret.push("Format can not be empty")

  if (properties.code.length===0)
      ret.push("Code can not be empty")
  return ret
}

/**
 * Data for new user modal
 */
export const new_user_modal = {
  _id: '',
  email: '',
  password: '',
  format: '%year%dealer%pre%user%counter%post%language',
  role: 'seller',
  code: '',
  note: '',
}

export const user_modal = ({ onSave, onClose, showSave }) => ({
  header: {
    icon: 'id badge',
    content: 'User'
  },
  inputs: [
    { name: "Name", value: '', property: '_id' },
    { name: "Email", value: '', property: 'email' },
    { name: "Password", value: '', property: 'password' },
    { name: "format", value: '%year%dealer%pre%user%counter%post%language', property: 'format' },
    { name: "Role", value: 'seller', dropdown: () => ['admin', 'dealer', 'seller'], property: 'role', type: 'options' },
    { name: "Code", value: '', property: 'code' },
    { name: "Notes", value: '', property: 'note', type: 'text'},
  ],
  showSave,
  onSave: (results, item, parent) => onSave(results, item, parent),
  onClose: () => onClose(),
})

export const user_format = item => {
  return `${item._id} - ${item.role}`
}



