import React, { useState, useEffect, useContext } from 'react';

import TableComponent from '../../component/TableComponent'
import { save_boilerplate, save_embedded_boilerplate } from '../../lib/common'
import { realm_call } from '../../lib/http'
import LoginContext from '../../LoginContext'

import {validate_dealer, dealer_modal} from './Dealer'
import {validate_user, new_user_modal, user_modal, user_format} from './User'

export let DEALER_MODAL = Symbol("DEALER_MODAL")
export let USER_MODAL = Symbol("USER_MODAL")

/**
 * Data to display either the dealer or user modal
 *
 * @param Symbol modal Either DEALER_MODAL or USER_MODAL
 * @param close The onClose callback
 * @param saveDeal The onSave callback for dealer modal
 * @param saveUser The onSave callback for user modal
 */
const select_modal = ({modal, close, saveDealer, saveUser}) => {
  if (modal === DEALER_MODAL) {
      return dealer_modal({
        showSave: true,
        onClose: close,
        onSave: saveDealer,
      })
  }

  if (modal === USER_MODAL) {
      return user_modal({
        showSave: true,
        onClose: close,
        onSave: saveUser,
      })
  }
}

export default props => {

  let [dealers, setDealers] = useState([])
  let [error_messages, setErrors] = useState([])
  let [success_messages, setSuccesses]= useState([])
  let [modal, setModal] = useState(DEALER_MODAL)
  let { token } = useContext(LoginContext).login

  async function fetchDealers () {
    let json = await realm_call({
      token, function: "read", args: [ "dealers"]
    })
    setDealers(json.dealers)
  }

  useEffect( () => {
    fetchDealers()
    // eslint-disable-next-line 
  }, []);

  const  saveDealer = async (results, edit_item) =>  {
    save_boilerplate({
        token,
        results,
        edit_item,
        setErrors,
        setSuccesses,
        state: true,
        all: fetchDealers,
        validate: validate_dealer,
        model_name: 'dealer',
        url: 'dealers',
    })
  }

  const saveUser = async (results, edit_item, parent) => {
    return save_embedded_boilerplate({
        token,
        results,
        state:true ,
        edit_item,
        parent,
        setErrors,
        setSuccesses,
        all: fetchDealers,
        validate: validate_user,
        model_name: 'dealer',
        url: 'users',
      })
    }

    let close= ()=>{ 
        setModal(DEALER_MODAL)
        setErrors([]); 
        setSuccesses([]) 
    }
  let active_form = select_modal({saveDealer, saveUser, modal, close})

  return (
    <TableComponent
      title="Dealer"
      header={['Name', 'Code', 'User', "New"]}
      body={dealers}
      error_messages={error_messages}
      success_messages={success_messages}
      body_format={[
        {
          type: 'link:modal',
          url: 'name',
        },
        'code',
        {
          type: 'array',
          array: 'users',
          // color: 'grey',
          format: user_format,
          onClick: (item, display_modal, parent) => {
            // alert(`User Clicked ${item._id}`)
            setModal(USER_MODAL)
            display_modal(item, parent)
          }
        },
        {
          type: 'button',
          onClick: (item, display_modal) => {
            setModal(USER_MODAL)
            display_modal(new_user_modal, item)
          },
          caption: 'New User'
        }
      ]}
      form={active_form}
    />
  )
}
