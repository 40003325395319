
export let embedded_store_item_form = ({onSave, onClose, showSave})=>({
  header: {
      icon: 'shop',
      content: 'Store Item',
  },
  inputs: [
      {name: "Key", value: '',     property: '_id'},
      {name: "Name", value: '',     property: 'name'},
      {name: "Price", value: '',     property: 'price'},
      // {name: "Type",  value: '',     property: 'type'},
      {name: "Type", value: 'collection', dropdown: ()=>['collection', 'program',], property: 'type', type: 'options' },
      {name: "Unlock",  value: '',     property: 'unlock'},
      {name: "Download",  value: '',     property: 'download'},
      {name: "Instructions",  value: '',     property: 'instructions', type: 'text'},
//       {name: "Email body",  value: '',     property: 'demo_email_body'},
//        {name: "Email subject",  value: '',     property: 'demo_email_subject'},
  ],
  showSave,
  onSave: (results, item, parent)=>onSave(results,item,parent),
  onClose: ()=>onClose(),
})
