import React from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css';
import App from './App';
// import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

const resize_watcher = new ResizeObserver(entries => {
    for (let entry of entries) {
        let width = entry.target.clientWidth
        let height = entry.target.clientHeight
        // Inform the parent of our iframe of our new size
        window.parent.postMessage({ kind: "resize", width, height }, "*")
    }
})
// When run inside an iframe
if (window.self !== window.parent && !window.opener) {
    // watch for the size of the react app changing
    resize_watcher.observe(document.getElementById('root'))
}
