import React from 'react';
import { Grid } from 'semantic-ui-react'
import { date_format } from '../../lib/util.js'

let customer_format = customer => `${customer.name} ${customer.email}`
let event_custom_input = events => {
  let rows = events.map(event => {
    return (
      <Grid.Row key={event._id}>
        <Grid.Column> {date_format(event.created_at)} </Grid.Column>
        <Grid.Column> {event.type} </Grid.Column>
        <Grid.Column width={4}> { event.code.code } </Grid.Column>
        <Grid.Column width={4}> {customer_format(event.customer)} </Grid.Column>
      </Grid.Row>
    )
  })

  return (
    <Grid columns={11} divided>
      {rows}
    </Grid>
  )
}

export let event_form = ({ onSave, onClose, showSave, }) => ({
  header: {
    icon: 'exchange',
    content: 'Event'
  },
  custom_inputs: event_custom_input,
  inputs: [
    { name: "Id", value: '', property: '_id' }, // drop down
    { name: "Message", value: '', property: 'message' },
  ],
  newButton: false,
  showSave,
  onSave: (results, item) => onSave(results, item),
  onClose: () => onClose(),
})
