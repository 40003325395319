import React from 'react';

const Home = props => {

  // let [text, setText] = useState("Loading")

  // useEffect(() => {
  //   let fetchTest = async () => {
  //     let json = await realm_call({function: "test", args: {one:1, two:2}})
  //     console.log( json )
  //     setText( JSON.stringify(json,0,2))
  //   }
  //   fetchTest()
  // }, []);

  return <div>
      <h1>Home</h1>
      {/* <p>{text}</p> */}

    </div>
}

export default Home