import React from 'react'
import { save_boilerplate } from '../../lib/common'
import {ModalComponent} from '../../component/TableComponent'

export const store_form = ({ onSave, onClose, showSave, dropdowns }) => ({
  header: {
    icon: 'building',
    content: 'Store'
  },
  inputs: [
    {
      name: "Language",
      value: 'English',
      dropdown: () => dropdowns.languages,
      property: 'language',
      type: 'options'
    }, // drop 
    {
      name: "Order Subject",
      value: "Hello {{ name }}",
      property: 'order_email_subject',
      type: 'input'
    },
    {
      name: "Order Body",
      value: "Hello {{ name }}",
      property: 'order_email_body',
      type: 'text'
    },
    {
      name: "Demo Subject",
      value: "Hello {{ name }}",
      property: 'demo_email_subject',
      type: 'input'
    },
    {
      name: "Demo Body",
      value: "Hello {{ name }}",
      property: 'demo_email_body',
      type: 'text'
    },
  ],
  showSave,
  onSave: (results, item) => onSave(results, item),
  onClose: () => onClose(),
})


const validate = properties => {
  let ret = []

  //    if (properties._id.length==0)
  //        ret.push("Language name can not be empty")

  //    if (properties.code.length==0)
  //        ret.push("Language code can not be empty")

  return ret
}


export const save = async ({
  order_email_body,
  order_email_subject,
  demo_email_body,
  demo_email_subject,
  store_id,
  all,
  setErrors,
  setSuccesses,
  setStore,
}) => {
  /*
    let values = {
        order_email_body: this.state.inputs.order_email_body,
        order_email_subject: this.state.inputs.order_email_subject,
        demo_email_body: this.state.inputs.demo_email_body,
        demo_email_subject: this.state.inputs.demo_email_subject,
    }
    let store = {_id: this.state.store_id}
    this.props.save(values, store)
    values => results,
    store => edit_item
  */

  let results = {
    order_email_body,
    order_email_subject,
    demo_email_body,
    demo_email_subject,
  }


  let edit_item = { _id: store_id }
  let result = await save_boilerplate({
    results,
    state: true,
    edit_item,
    all,
    validate,
    setErrors,
    setSuccesses,
    model_name: 'store',
    url: 'store',
  })
  let json = await result.json()
  setStore(json)
}

const languagesNotInStore = ({stores, languages}) =>{
  let store_languages = stores.map( store=>store.language)
  return languages.filter( l=>!store_languages.includes(l._id) )
}

export const createStoreModal = ({store, stores, onClose, onSave, languages, error_messages, success_messages})=>{
  return <ModalComponent
    error_messages={error_messages}
    success_messages={success_messages}
    display={true}
    form={store_form(({
      onSave,
      onClose,
      showSave:true,
      dropdowns: {
        languages: languagesNotInStore({languages, stores}).map( language=>language._id ),
        },
      }))}
    saveLabel={ store ? "Save" : "Create"}
    onClick={item=>onSave(item)}
    onClose={()=>onClose()}
    showSave={true}
    editItem={store}
  />
}

export const  saveStore = ({token, results, all, edit_item, setErrors,setSuccesses,}) => {
  return save_boilerplate({
      token,
      results,
      state: true,
      edit_item,
      edit_item_prefix: "id",
      all,
      validate,
      setErrors,
      setSuccesses,
      model_name: 'store',
      url: 'stores',
  })
}
