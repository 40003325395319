import { cloudflare_call } from '../../lib/http'

export const preview_demo = async ({token, store_items, store, cart, setDemoEmail}) => {
    let cart_items = cart.map( key=>store_items.find( item=>item._id===key ))
    if (cart_items.length!==1) {
        setDemoEmail("Select one program to get a preview.")
        return
    }


    // console.log("Cart Items", cart_items)

    let body = JSON.stringify(
      {
        store,
        cart_item: cart_items[0],
        name: "Test User"
      }
    )
    let url = '/email/preview/demo'
    let json = await cloudflare_call({token, url, body})
    setDemoEmail(`${json.subject}\n====================\n${json.body}`)
}

export const preview_order = async ({token, store_items, store, cart, setOrderEmail}) => {
  // Find each cart item in the store
  let cart_items = cart.map( key=>store_items.find( item=>item._id===key ))

  let total = cart_items.reduce( (total, i)=>i.price*100+total, 0)
  // TODO This should be handled in the template language
  total = `$${total/100}.00`
  let body = JSON.stringify(
    {
      store,
      items: cart_items,
      total
    }
  )
  let url = '/email/preview/order'

  // Hit server to create the email
  let json = await cloudflare_call({token, url, body})
  // console.log("JSON", json)

  if (!json.email) json.email = "test@patternmakerusa.com"

  setOrderEmail(`${json.subject}\n====================\n${json.body}`)
}

