import React from 'react';
import {date_format, currency_format} from "./util"
import { Grid } from 'semantic-ui-react'

let currency = price => currency_format(price / 100)

export let order_format = item => {
  let date = date_format(item.created_at)
  return `${date} - ${currency(item.total)}`
}

let order_custom_input = order => {
  let rows = order.items.map(item => {
    // console.log(item)
    return (
      <Grid.Row key={item.name}>
        <Grid.Column width={4}> {item.name} </Grid.Column>
        <Grid.Column width={4}> {currency_format(item.price)} </Grid.Column>
      </Grid.Row>
    )
  })

  return (
    <Grid columns={8} divided>
      {rows}
    </Grid>
  )
}

export let order_form = ({ onClose, onSave, showSave }) => ({
  header: {
    icon: 'money',
    content: 'Order'
  },
  custom_inputs: order_custom_input,
  inputs: [
    { name: "Name", value: '', property: '_id' },
    { name: "Keys", value: '', property: 'store_keys' },

  ],
  newButton: false,
  showSave,
  onSave: (results, item) => onSave(results, item),
  onClose: () => onClose(),
})