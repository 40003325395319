import React from 'react';
import { Label, List } from 'semantic-ui-react'

export default props => {
    let {
		text,
		color = 'blue',
		onClick,
    } = props

    return <List.Item onClick={onClick}>
    	<Label circular
        	key={text}
        	color={color}
        	style={{ cursor: 'pointer' }}
    	>
			{text}
    	</Label>
    </List.Item>
}