
let ENV = {
    // Replace || with ?? when ?? is supported
    cloudflare: process.env.REACT_APP_BACKEND_URL || window.location,
}

export const login_call = async (user, password) => {
    const url = new URL("/login", ENV.cloudflare);

    // Put the Authorization in the header.  Use btoa to ensure special headers
    // do not interfear with
    const headers = {Authorization: `Basic ${btoa(user + ':' + password)}`}
    let response = await fetch(
        url,
        {
            headers,
            mode: 'cors',
            "method": "POST",
        },
    )
    if (response.status===200)
        try {
            return await response.json()
        } catch (e) {
        }
    // else
        // throw Error("User name or password is wrong")
    return {}
}

export const realm_call = async ({token, function: function_name, args}) => {
    const url = new URL("/invoke", ENV.cloudflare);
    let headers = {
        'Content-Type': 'application/json',
    }

    // TODO Puts authorization token into header
    if (token)
        headers["Authorization"] = `Bearer ${token}`

    let response = await fetch(
        url.href,
        {
            headers,
            mode: 'cors',
            "method": "POST",
            "body": JSON.stringify({function: function_name, args})
        }
    )
    let json = await response.json()
    return json
}

export const cloudflare_call = async ({ token, url, body, state = undefined }) => {
    const url2 = new URL(url, ENV.cloudflare);

    // try {
    let response = await fetch(
        url2.href,
        {
            "headers": {
                // TODO Puts authorization token into header
                "Authorization": `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            "method": "POST",
            "body": JSON.stringify({body})
        }
    ) 
    let json = await response.json()
    return json
}
