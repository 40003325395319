import React, { useState, useEffect, useContext } from 'react';

import TableComponent from '../../component/TableComponent'
import { save_boilerplate } from '../../lib/common'
import { customer_form } from '../../lib/customer'
import { order_format, order_form } from '../../lib/order'
import { display_code_form } from './Code'
import { realm_call } from '../../lib/http'
import LoginContext from '../../LoginContext'
import Loader from 'react-loader-advanced';

let ORDER_MODAL = Symbol("ORDER_MODAL")
let CUSTOMER_MODAL = Symbol("CUSTOMER_MODAL")
let CODE_MODAL = Symbol("CODE_MODAL")

export const validate = properties => {
    let ret = []

    if (properties.name.length===0)
        ret.push("Name can not be empty")

    if (properties.email.length===0)
        ret.push("Email can not be empty")

    return ret
}

const select_modal = ({ modal, onSave, onClose }) => {
    if (modal === CUSTOMER_MODAL) return customer_form({ onClose, onSave, showSave: true })
    if (modal === ORDER_MODAL) return order_form({ onClose })
    if (modal === CODE_MODAL) return display_code_form({ onClose })
}

export default props => {
    let [busy, setBusy] = useState(false)
    let [customers, setCustomers] = useState([])
    let [error_messages, setErrors] = useState([])
    let [success_messages, setSuccesses] = useState([])
    let [modal, setModal] = useState(CUSTOMER_MODAL)
    // Pagination
    let [pages, setPages] = useState(1)
    let [current_page, setCurrentPage] = useState(1)
    let [per_page, /* setPerPage */ ] = useState(10)
    let [search, setSearch] = useState('')
    let { token } = useContext(LoginContext).login
    let filter = [
        {name: "Name", key: "name", checked: true},
        {name: "Email", key: "email"},
    ]
    let [filter_values, setFilterValues] = useState(
        filter.map( item=>item.checked)
    )

        async function fetchCustomers () {
            setBusy(true)
            let search_on = 'name'
            if (filter_values[1])
                search_on = 'email'
            let  json = await realm_call({ token, function: "read", args: [ "customers", {current_page, per_page, search, with: "codes", search_on}] })

            setCustomers(json.customers)
            setPages(json.count)
            setBusy(false)
        }

    // init Customers
    useEffect(() => {
        fetchCustomers()
        // eslint-disable-next-line
    }, [current_page, per_page, search])

    let save = async (results, edit_item) => {
        return save_boilerplate({
            token,
            results,
            edit_item,
            setErrors,
            setSuccesses,
            state: true,
            all: () => fetchCustomers(),
            validate,
            model_name: 'customer',
            url: 'customer',
        })
    }

    let close = () => {
        // Reset values to their default
        setModal(CUSTOMER_MODAL);
        setErrors([]);
        setSuccesses([])
    }

    let active_form = select_modal({ modal, onSave: save, onClose: close })

    return (
        <Loader show={busy} message={'loading'}>

            <TableComponent
                title="Customers"
                header={['Date', 'Name', 'Email', 'Codes', 'Orders', 'Notes']}
                filter={filter}
                filter_callback={ value=>setFilterValues(value) }
                body={customers}
                error_messages={error_messages}
                success_messages={success_messages}
                body_format={[
                    { type: 'date', date: 'created_at', },
                    { type: 'link:modal', url: 'name', },
                    { type: 'link:email', email: 'email', },
                    {
                    type: 'array',
                    array: 'codes',
                    color: 'grey',
                    format: item => item.code,
                    onClick: (code, display_modal) => {
                        setModal(CODE_MODAL)
                        display_modal(code)
                    }
                    },
                    {
                    type: 'array',
                    array: 'orders',
                    format: order_format,
                    color: 'violet',
                    visible: code => {
                        if (!code.orders) return false
                        return code.orders.length !== 0
                    },
                    onClick: (items, display_modal) => {
                        setModal(ORDER_MODAL)
                        display_modal(items)
                    }
                    },
                    'note',
                ]}

                onSearch={filter => { setCurrentPage(1); setSearch(filter)} }
                paginate={{
                    pages,
                    current_page,
                    onClick: p => setCurrentPage(p)
                }}

                form={active_form}
            />
        </Loader>
    )
}
