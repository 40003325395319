
/**
 * Validates dealer data before saving
 *
 * @param {*} properties The data to validate
 */
export const validate_dealer = properties => {
  let ret = []

  if (properties.name.length===0)
      ret.push("Name can not be empty")

  if (properties.code.length===0)
      ret.push("Code can not be empty")

  return ret
}

/**
 * The New/Save modal forms display data
 *
 * @param Function onSave Callback for saving
 * @param Function onClose Callback for closing the dialog
 * @param Boolean showSave Should the save button be displayed
 */
export let dealer_modal = ({ onSave, onClose, showSave }) => ({
  header: {
    icon: 'users',
    content: 'Dealers'
  },
  inputs: [
    { name: "Name", value: '', property: 'name' },
    { name: "Code", value: '', property: 'code' },
    { name: "Notes", value: '', property: 'note', type: 'text'},
  ],
  showSave,
  onSave: (results, item) => onSave(results, item),
  onClose: () => onClose(),
})


