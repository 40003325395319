import React, { useState, useEffect } from 'react'
import { List, Segment, } from 'semantic-ui-react'

export default props => {
  let [open, setOpem] = useState(false)
  useEffect(() => {
    setOpem(props.open)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let icon = open ? 'angle down' : 'angle right'
  let expand = <Segment>{props.children}</Segment>

  return (
    <List.Item>
      <List.Icon name={icon} onClick={() => setOpem(!open)} />
      <List.Content>
        <List.Header>{props.header}</List.Header>
        {open ? expand : ''}
      </List.Content>
    </List.Item>
  )
}