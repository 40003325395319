
export const date_format = date => {
  if (!date)
    return "no date"
  if (typeof date === 'string' || typeof date === 'number')
    date = new Date(date)
  return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
}

export const currency_format = price => {
  // Create our number formatter.
  let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    // the default value for minimumFractionDigits depends on the currency
    // and is usually already 2
  });

  return formatter.format(price);
}

/**
 * Counts the number of a type of events
 *
 * @param {Array} events
 * @param {String} type
 */
export const count_events = (events, type) => events.reduce((total, event) => event.type === type ? total + 1 : total, 0)

