
import React, { Component } from 'react';

export default class Search extends Component {

    constructor(props) {
        super(props);
        this.search = ''
    }

    keyPress(event) {
    
    if (event.key === "Enter") {
        let search = event.target.value.trim()

        // eat unchanged results
        if (this.search === search)
            return

        this.search = search
        this.props.search(search)
        }
    }

    render() {
        return <span className="ui search">
            <div className="ui icon input">
                <input
                    className="prompt"
                    placeholder="Search..."
                    type="text"
                    style={ {width: "250px"}}
                    onKeyDown={e => this.keyPress(e)}
                />
                <i className="search icon" />
            </div>
        </span>
    }
}
