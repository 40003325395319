import React, { useState, useEffect, useContext } from 'react';
import TableComponent from '../../component/TableComponent'
import { customer_form } from'../../lib/customer'
import { currency_format } from'../../lib/util'
import { realm_call } from '../../lib/http'
import LoginContext from '../../LoginContext'

export default props => {

  let [orders, setOrders] = useState([])
  let [error_messages, setErrors] = useState([])
  let [success_messages, setSuccesses] = useState([])
  // Pagination
  let [pages, setPages] = useState(1)
  let [current_page, setCurrentPage] = useState(1)
  let [per_page, /* setPerPage */] = useState(10)
  let { token } = useContext(LoginContext).login

  useEffect(() => {
    // fetchOrders({per_page, current_page, state:true, setOrders, setPages})
    let fetchOrders = async ()=> {
      let  json = await realm_call({ token, function: "read", args: [ "orders", {current_page, per_page}] })
      let { orders, pages } = json
      setOrders(orders)
      setPages(pages)  
    }
    fetchOrders();
  }, [token, per_page, current_page])

  let close = () => {
    // Reset values to their default
    setErrors([]);
    setSuccesses([])
  }


  // let { create, edit } = this.props
  let active_form = customer_form({onClose: close})
 // let customer_format = this.customer_format

 console.log("Orders", orders)
//  orders = orders.map( order=>{
//    return order.orders_embed
//    console.debug(order) 
//    return { 
//      created_at: order.orders_embed.created_at,
//       total: order.orders_embed.total 
//     }
//   } )

  return (
    <TableComponent
      title="Order"
      header={['Date', 'Total', 'Items', 'Customer']}
      body={orders}
      error_messages={error_messages}
      success_messages={success_messages}
      body_format={[
        { type: 'date', date: 'created_at', },
        { type: 'custom', format: item => currency_format(item.total / 100) },
        {
          type: 'array',
          format: item => { console.log("Item", item); return `${item.name}: ${currency_format(item.price)}` },
          array: 'items',
        },
        {
          type: 'array:one',
          format: item => item.name,
          onClick: (customer, display_modal) => display_modal(customer),
          color: 'violet',
        },
      ]}
      paginate={{
        pages,
        current_page,
        onClick: p => setCurrentPage(p),
      }}

      form={active_form}
    />
  )
}


