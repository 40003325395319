
export let code_form = ({ onSave, onClose, showSave, dropdowns, user }) => ({
  header: {
    icon: 'qrcode',
    content: 'Code'
  },
  inputs: [
    { name: "User", property: 'user', value: user, type: 'hidden' },
    // value undefined hides Code on new
    { name: "Code", value: undefined, property: 'code'},
    { name: "Format", value: user.format, property: 'format' },
    { name: "Programs", value: 'Professional', dropdown: () => dropdowns.programs, property: 'program', type: 'options' },
    { name: "Demo Days", value: 30, property: 'demo_day' },
    { name: "Language", value: 'English', dropdown: () => dropdowns.languages, property: 'language', type: 'options' }, // drop down
    { name: "Registrations", value: 2, property: 'registration' },
    { name: "Resets", value: 0, property: 'reset' },
    { name: "Site License", value: false, property: 'site_license', type: 'checkbox' }, // checkbox
    { name: "Notes", value: '', property: 'note', type: 'text' }
  ],
  // newButton: false,
  showSave,
  onSave: (results, item) => onSave(results, item),
  onClose: () => onClose(),
})

export const validate = properties => {
  const isInt = str => {
    let reg = /^(\d+)$/
    return str.toString().trim().match(reg)
  }

  let ret = []
  if ( !isInt(properties.demo_day))
      ret.push("Demos days must be an integer")

  if ( !isInt(properties.registration))
      ret.push("Registrations must be an integer")

  if (!isInt(properties.reset))
      ret.push("Resets must be an integer")
  return ret
}

