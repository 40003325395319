import React, { Component } from 'react';
import { Button, Header, Icon, Modal, List } from 'semantic-ui-react'
import { Form, Message } from 'semantic-ui-react'
import { date_format } from '../../lib/util.js'

export default class ModalComponent extends Component {

  constructor(props) {
    super(props);

    // Iniitialize inputs
    let form = {}

    // Create state.  Read from editing object if it exists
    for (let p of props.form.inputs)
      form[p.name] = props.editItem ? props.editItem[p.property] : p.value

    this.state = { form }
  }

  content() {
    let { form, editItem } = this.props
    let inputs

    if (form.custom_inputs) // For events in Code form
      inputs = form.custom_inputs(editItem)
    else
      inputs = form.inputs.map(f => this.content_input(f))

    let header = form.header
    if (header)
      header = <Header
        icon={header.icon}
        content={header.content}
      />

    return (
      <Form>
        {header}
        {inputs}
      </Form>
    )
  }

  content_input(f) {
    let name = f.name
    let type = f.type || 'input'
    // Read data from state, ie the typed input
    let value = this.state.form[name]

    // This is a kludge that makes the notes field appear
    // even when it is empty, which results in it being null
    // The disappearing while null is what makes editing a 
    // password disappear
    if (type==='text')
      value = value || ''

    // This is a kludge to prevent crashes when
    // the form has been switched before the
    // data
    if (value === undefined)
      return undefined

    let change = (_, { value }) => {
      let s = Object.assign({}, this.state)
      s.form[name] = value
      this.setState(s)
    }

    switch (type) {
      case 'hidden': return null
      case 'list': {
        let format = f.format
        let key = 0
        value = value.map(i => {
          key++
          // Use passed formatter
          if (format) i = format(i)
          return <List.Item key={`key_${key}`}>{i}</List.Item>
        })
        return (
          <Form.Field key={`field_${f.name}`}>
            <label>{name}</label>
            <List key={f.name}>
              {value}
            </List>
          </Form.Field>
        )
      }
      case 'date': {
        value = date_format(value)
        return (
          <Form.Input key={name}
            label={name}
            value={value}
            onChange={change}
          />)
      }
      case 'input': return (
        <Form.Input key={name}
          label={name}
          value={value}
          onChange={change}
        />)
      case 'text': return (
        <Form.TextArea key={name}
          label={name}
          value={value}
          onChange={change}
        />)
      case 'checkbox': {
        let checkboxChange = (_, { checked }) => {
          let s = Object.assign({}, this.state)
          s.form[name] = checked
          this.setState(s)
        }
        return (
          <Form.Checkbox key={name}
            label={name}
            checked={value}
            onChange={checkboxChange}
          />)
      }
      case 'options': {
        const options = f.dropdown().map(p => ({ text: p, value: p }))

        return (
          <Form.Select key={name}
            label={name}
            options={options}
            value={value}
            onChange={change}
          />)
      }
      default: throw Error(`Unknown type ${type}`)
    }

  }

  messages() {
    let { error_messages = [], success_messages = [] } = this.props

    error_messages = error_messages.length === 0 ? null : error_messages
    success_messages = success_messages.length === 0 ? null : success_messages

    if (!error_messages && !success_messages)
      return null

    return (
      <Modal.Header>
        {error_messages ?
          <Message
            error
            icon="warning sign"
            list={error_messages}
          /> : undefined
        }

        {success_messages ?
          <Message
            success
            icon="info circle"
            list={success_messages}
          /> : undefined
        }
      </Modal.Header>
    )
  }

  onClick() {
    let result = {}

    // collect properties
    for (let p of this.props.form.inputs)
      result[p.property] = this.state.form[p.name]

    // call the onClick callback in contaner
    this.props.onClick(result, this.props.editItem, this.props.parentItem)
  }

  render() {
    let { display, saveLabel } = this.props
    let { onClose } = this.props
    let { showSave } = this.props.form

    let contents = this.content()
    let onClick = this.onClick.bind(this)

    let messages = this.messages()

    return (
      <Modal
        open={display}
        onClose={onClose}
      >

        {messages}
        <Modal.Content>
          {contents}
        </Modal.Content>

        <Modal.Actions>
          {!showSave ? '' :
            <Button color='green' onClick={onClick} >
              <Icon name='add circle' />
              {saveLabel}
            </Button>
          }
          <Button color='blue' onClick={onClose} >
            <Icon name='checkmark' />
            Close
          </Button>
        </Modal.Actions>

      </Modal>
    )

  }
}
