
export let customer_form = ({ onSave, onClose, showSave }) => ({
  header: {
    icon: 'user',
    content: 'Customer'
  },
  inputs: [
    { name: "Name", value: '', property: 'name' },
    { name: "Email", value: '', property: 'email' },
    { name: "Created", value: '', property: 'created_at', type: 'date' },
    // { name: "Codes", value: '', property: 'codes', type: 'list' },
    // { name: "Orders", value: '', property: 'orders', type: 'list', format: order_format },
    { name: "Notes", value: '', property: 'note', type: 'text' },
  ],
  newButton: false,
  showSave,
  onSave: (results, item) => onSave(results, item),
  onClose: () => onClose(),
})

export let customer_format = customer => customer.name
