import React, { useState, useEffect, useContext } from 'react'
import { List, Button, Header } from 'semantic-ui-react'
import { createStoreModal, saveStore } from './Store'
import { realm_call } from '../../lib/http'

import LoginContext from '../../LoginContext'
import { Link } from 'react-router-dom'
import {StoreContext} from './StoreContext'

export {StoreContainer} from './StoreContainer'
export {StoreContext}

export default props => {
  let [stores, setStores] = useState([])
  let [dealers, setDealers] = useState([])
  let [selectedStore, setSelectedStore] = useState()
  let [selectedDealer, setSelectedDealer] = useState()
  let [programs, setPrograms] = useState([])
  let [languages, setLanguages] = useState([])

  let { user, token } = useContext(LoginContext).login

  // fetch the list of languages
  useEffect(() => {
    async function fetchCollections() {
      let  [
        languages_json,
        dealers_json,
        stores_json,
        program_json,
      ] = await Promise.all([
        realm_call({ token, function: "read", args: [ "languages"] }),
        realm_call({ token, function: "read", args: [ "dealers"] }),
        realm_call({ token, function: "read", args: [ "stores"] }),
        realm_call({ token, function: "read", args: [ "programs"] }),
      ])

      setPrograms(program_json.programs)
      setLanguages(languages_json.languages)
      setDealers(dealers_json.dealers)
      setStores(stores_json.stores)
    }
    fetchCollections()
    // eslint-disable-next-line 
  }, []);

  useEffect(() => {
    if (!dealers || dealers.length===0) return
    // Find which dealer the user belongs to
    let dealer = dealers.find( dealer =>{
        return dealer.users.find( u=>{
            // console.log("Searching users ", u._id, user._id, u.email===user.email)
            return u.email===user.email
        })
      })

    // let store = stores.find( s=>`/store/${s._id}`===props.location.pathname )
    let store = stores.find( store=>store.dealer===dealer._id)
    if (store)
      setSelectedStore(store);
    setSelectedDealer(dealer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stores, dealers])

  let [showModal, setShowModal] = useState(false)
  let [error_messages, setErrorMessages] = useState([])
  let [success_messages, setSuccessMessages] = useState([])

  
  let modal
  // Save Store callback

  let { dealer } = useContext(LoginContext).login
  // result.dealer = result.dealer || dealer._id


  let onSave = results=>saveStore({
    // results: {...results, dealer: selectedDealer._id},
    results: {...results, dealer: dealer._id},
    // all: fetchStore,
    setErrors: messages => setErrorMessages(messages),
    setSuccesses: messages =>setSuccessMessages(messages),
  })
  let onClose = () => {
    setShowModal(false);
    setErrorMessages([])
    setSuccessMessages([])
  }

  if (showModal)
    modal = createStoreModal({
      stores,
      onClose,
      onSave,
      languages,
      error_messages,
      success_messages
    })

    let [, sendToChild] = React.useContext(StoreContext)

    useEffect( ()=>{
      sendToChild({selectedDealer, selectedStore, languages, programs})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDealer, selectedStore, languages, programs])


    let storeList = stores.map(store => {
      if (dealers.length === 0) return undefined
      let dealer = dealers.find(d =>  store.dealer === d._id )

      return <List.Item
        key={store._id}
        style = {{ cursor: "pointer" }}
      >
        <Link
          to={`/store/${store._id}`}
          onClick={() => {
            setSelectedStore(store);
            setSelectedDealer(dealer)
          }}
        >
          {dealer._id} - {store.language}
        </Link>
      </List.Item>
    })
    return (
      <>
        {modal}
        <Button color="green" onClick={()=>{setShowModal(true)}}>
          New
        </Button>
        <Header>
            Select a Store
        </Header>
        <List>
          {storeList}
        </List>
      </>
    )
}
