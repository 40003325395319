import React, { useState, useEffect, useContext } from 'react'
import { Container, List, Button, Form, TextArea, Checkbox, Label, Input, Message } from 'semantic-ui-react'
import Expand from '../../component/Expand'
import { embedded_store_item_form } from './Item'
import { preview_order, preview_demo } from './Preview'
import { ModalComponent } from '../../component/TableComponent'
import { saveStore } from './Store';
import LoginContext from '../../LoginContext'

export let store_format = item => {
    return `${item._id}`
}

/**
 * Test if an item is in the cart
 *
 * @param {array} cart The items in the cart
 * @param {string} item The key of the item in the cart
 * return true if the item is in the cart
 */
let inCart = (cart, item) => cart.includes(item)
/**
 * Removes an item if it is in the cart, otherwise adds it
 *
 * @param {array} cart The items in the cart
 * @param {string} item The key of the item in the cart
 */
let toggleInCart = (cart, key) => {
    let ret = [ ...cart ]
    if (ret.includes(key)) {
        ret = ret.filter( w=>key!==w)
    }
    else
        ret.push(key)
    return ret
}

const COLLECTION = Symbol('collection')
const PROGRAM = Symbol('program')

let newCollection = {
    _id: 'Unique Key',
    name:  'New Collection',
    price: '3000',
    type: 'collecton', // 	['program', 'collection']
    unlock: 'code sent to use',
    download: 'url',
    instructions:  'Any special instuctions (like on Corset)',
//    demo_email_body: '',
//    demo_email_subject: '',
}

let newProgram = {
    _id: 'Unique Key',
    name:  'Program Collection',
    price: '9900',
    type: 'program', // 	['program', 'collection']
    unlock: '',
    download: 'url',
    instructions:  '',
    demo_email_body: '',
    demo_email_subject: '',
}


const createItemModal = ({newItemType, storeItem, onClose, onSave})=>{
    let isNew = storeItem===null

    if (isNew) {
    if (newItemType===COLLECTION)
        storeItem = newCollection;
    else
        storeItem = newProgram
    }

    return <ModalComponent
        display={true}
        form={embedded_store_item_form({onSave, onClose, showSave: true})}
        saveLabel={ isNew ? "Save" : "Create"}
        onClick={item=>onSave(item)}
        onClose={()=>onClose()}
        showSave={true}
        editItem={storeItem}
    />
}

/**
 * Creates a checkbox list of either programs or collections
 * for display in an expand
 *
 * @param {array} cart The items in the cart
 * @param {string} item The key of the item in the cart
 * @param {function} setCart Setter
 */
let createStoreItemList = (cart, item, setCart, editItem) => (
    <List.Item key={item._id} >
        <Checkbox
            label={item.name}
            checked={inCart(cart, item._id)}
            onClick={() => setCart(toggleInCart(cart,item._id))}
        />
        {" "}
        <Label
            content="Edit"
            style = {{ cursor: "pointer" }}
            onClick={()=>editItem(item)}
        />
    </List.Item>
)

export default props => {
    let [cart, setCart] = useState([])

    let { store } = props
    let [orderSubject, setOrderSubject] = useState(store.order_email_subject)
    let [orderBody, setOrderBody] = useState(store.order_email_body)
    let [orderEmail, setOrderEmail] = useState('')

    let [fromEmail, setFromEmail] = useState(store.from_email)
    let [demoSubject, setDemoSubject] = useState(store.demo_email_subject)
    let [demoBody, setDemoBody] = useState(store.demo_email_body)
    let [demoEmail, setDemoEmail] = useState('')
    let [dirty, setDirty] = useState(false)
    let { token } = useContext(LoginContext).login
    let [buffered_store_items, setBufferedStoreItems] = useState([])

    useEffect( ()=>{
        // It is annoying to have to create a function to compare arrays, but
        // it was not working any other way
        //
        // Ref https://flexiple.com/javascript-array-equality/
        function arrayEquals(a, b) {
            if ( Array.isArray(a) &&
                Array.isArray(b) &&
                a.length === b.length) {
                    // sort the array so order does not effect the comparison
                    let array_1 = a.sort( (x,y)=>x._id>y._id)
                    let array_2 = b.sort( (x,y)=>x._id>y._id)
                    // The === compairs memory values, convert to json
                    // then compair the strings
                    return JSON.stringify(array_1) === JSON.stringify(array_2)
                }
            return false;
        }

        if(
            store.order_email_subject !== orderSubject ||
            store.order_email_body !== orderBody ||
            store.demo_email_subject !== demoSubject ||
            store.demo_email_body !== demoBody ||
            !arrayEquals(store.items, buffered_store_items)
        )
            setDirty(true)
        else
            setDirty(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderSubject, orderBody, demoSubject, demoBody, buffered_store_items ])

    useEffect( ()=>{
        let buffer = [...store.items]
        setBufferedStoreItems(buffer)
        setOrderSubject(store.order_email_subject)
        setOrderBody(store.order_email_body)
        setDemoSubject(store.demo_email_subject)
        setDemoBody(store.demo_email_body)
    }, [store])

    let onSaveStore = ()=>{
        store.order_email_subject = orderSubject
        store.order_email_body = orderBody
        store.demo_email_subject = demoSubject
        store.demo_email_body = demoBody
        store.items = buffered_store_items
        store.from_email = fromEmail
        saveStore({
            token,
            results: store,
            all: () => { /* props.setLoadStores(true) */ },
            edit_item: store,
            setErrors: errors=>alert(errors),
            setSuccesses: ()=>{;},
        })
        setDirty(false)
    }

    useEffect( ()=>{
        preview_order({
            store_items: buffered_store_items,
            store,
            cart,
            setOrderEmail
        })
        // Disabled waring since store is not a dependany
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buffered_store_items, orderSubject, orderBody, cart])

    useEffect( ()=>{
        setDemoEmail("Demo")
        preview_demo({
            token,
            store_items: buffered_store_items,
            store,
            cart,
            setDemoEmail
        })
        // Disabled waring since store is not a dependany
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buffered_store_items, demoSubject, demoBody, cart])

    // Store item modal
    let [itemModal, setItemModal] = useState(false)
    let [storeItem, setStoreItem] = useState()

    let editItem = (item) => {
        setItemModal(true);
        setStoreItem(item)
    }

    let [newItemType, setNewItemType] = useState()
    let createItem = itemType => { setItemModal(true); setStoreItem(null); setNewItemType(itemType) }
    let saveItem = (item) => {
        // Set dirty because the store needs to be saved
        setDirty(true)

        setItemModal(false);

        let found = false
        let new_list = buffered_store_items.map(b=>{
            // put item into the buffer
            if (item._id!==b._id) return b
            found=true
            return item;
        })
        if (!found) new_list.push(item)
            setBufferedStoreItems(new_list)
    }

    let isCollection = item => item.type==='collection'

    let sorted = buffered_store_items.sort( (a,b)=>a.name>b.name)
    let m_programs = sorted.filter(i=>!isCollection(i)).map(program => createStoreItemList(cart, program, setCart, editItem) )
    let m_collection = sorted.filter(i=>isCollection(i)).map(collection => createStoreItemList(cart, collection, setCart, editItem) )

    let modal
    if (itemModal) modal = createItemModal({newItemType, storeItem, onClose: ()=>setItemModal(false), onSave: saveItem})

    let save_warning
    if (dirty) save_warning =   <Message
        warning
        header='Store modified but not saved'
        content='Be sure to save your work'
    />

    return (
        <>
            {modal}
            {save_warning}

            <Container>
            <h2>Store</h2>
            <List>
                <Expand header="Collections">
                <List link>
                    {m_collection}
                </List>
                <Button onClick={()=>createItem(COLLECTION)}>Add Item</Button>
                </Expand>

                <Expand header="Programs">
                <List>
                    {m_programs}
                </List>
                <Button onClick={()=>createItem(PROGRAM)}>Add Program</Button>
                </Expand>

                <Expand header="Order Template">
                <Form>
                    <Form.Field>
                    <label>Subject</label>
                    <Input value={orderSubject} onChange={(_, comp) => setOrderSubject(comp.value)} />
                    </Form.Field>
                    <Form.Field>
                    <label>Body</label>
                    </Form.Field>
                    <TextArea rows="6" placeholder='Enter email subject' value={orderBody} onChange={(_, comp) => setOrderBody(comp.value)} />
                </Form>
                </Expand>

                <Expand header="Demo Template">
                <Form>
                    <Form.Field>
                    <label>Subject</label>
                    <Input value={demoSubject} onChange={(_, comp) => setDemoSubject(comp.value)} />
                    </Form.Field>
                    <Form.Field>
                    <label>Body</label>
                    </Form.Field>
                    <TextArea rows="6" placeholder='Enter email subject' value={demoBody} onChange={(_, comp) => setDemoBody(comp.value)} />
                </Form>
                </Expand>

                <Expand header="Order Email" >
                <pre>
                    {orderEmail}
                </pre>
                </Expand>

                <Expand header="Demo Email">
                <pre>
                    {demoEmail}
                </pre>
                </Expand>

                <Expand header="From Email Address">
                <Form>
                    <Form.Field>
                    <label>Email</label>
                    <Input value={fromEmail} onChange={(_, comp) => setFromEmail(comp.value)} />
                    </Form.Field>
                </Form>
                </Expand>

                <Button color="blue" onClick={()=>onSaveStore()} >
                    Save
                </Button>

            </List>
            <br />
            <br />

            </Container>
        </>
    )
}
