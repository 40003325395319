import React, { useState, useEffect, useContext } from 'react';

import TableComponent from '../component/TableComponent'
import {save_boilerplate} from '../lib/common'
import {realm_call} from '../lib/http'
// import {StoreContext} from './StoreContext'
import LoginContext from '../LoginContext'

let language_form = ({ onSave, onClose, showSave }) => ({
  header: {
    icon: 'diamond',
    content: 'Language'
  },
  inputs: [
    { name: "Name", value: '', property: '_id' },
    { name: "Code", value: '', property: 'code' },
  ],
  showSave,
  onSave: (results, item) => onSave(results, item),
  onClose: () => onClose(),
})

const validate = properties => {
  let ret = []

  if (properties._id.length===0)
      ret.push("Language name can not be empty")

  if (properties.code.length===0)
      ret.push("Language code can not be empty")

  return ret
}

export default props => {
  let [languages, setLanguages] = useState([])
  let [error_messages, setErrors] = useState([])
  let [success_messages, setSuccesses]= useState([])
  let { token } = useContext(LoginContext).login

  async function fetchLanguages () {
    let json = await realm_call({
      token, function: "read", args: [ "languages"]
    })

    setLanguages(json.languages)
  }

  let  saveCallback = async (results, edit_item) =>  {
    save_boilerplate({
        token,
        results,
        edit_item,
        setErrors,
        setSuccesses,
        state: true,
        all: fetchLanguages,
        validate,
        model_name: 'language',
        url: 'languages',
    })
  }

  useEffect( () => {
    fetchLanguages()
    // eslint-disable-next-line 
  }, []);

  return (
    <TableComponent
      title="Language"
      header={['Name', 'Code']}
      body={languages}
      error_messages={error_messages}
      success_messages={success_messages}
      body_format={[
        {
          type: 'link:modal',
          url: '_id',
        },
        'code',
      ]}
      form={
        language_form({
          showSave: true,
          onSave: saveCallback,
          onClose: ()=>{ setErrors([]); setSuccesses([]) }
        })
      }
    />
  )
}