import React, { Component } from 'react';
import { Table } from 'semantic-ui-react'
import { Button, Icon } from 'semantic-ui-react'

import ModalComponent from './ModalComponent.jsx'
import ArrayComponent from './ArrayComponent.jsx'
import Pagination from './Pagination.jsx'
import Search from './Search.jsx'
import { date_format } from '../../lib/util.js'

import { List, Checkbox, Container } from 'semantic-ui-react'

export {ModalComponent};
export default class TableComponent extends Component {

    constructor(props) {
        super(props);

        let filter_values
        // If there is a filter, extract the filter.checked for the
        // initial state of the checkboxes
        if (props.filter)
            filter_values = props.filter.map( item=>item.checked===true )

        this.state = {
            modalOpen: false,
            modalData: null,
            filter_values,
        };
    }

  preformat(format, item) {
    let data = item[format.item]
    return (
      <pre>
        {data}
      </pre>
    )
  }

  date(format, item) {
    let date = item[format.date]
    return date_format(date)
  }

  email_link(format, item) {
    let email = item[format.email]
    return (
      <a href={`mailto:${email}`}>{email}</a>
    )
  }

  button(format, item) {
    // let button = item[format.button]
    let { caption, color = "olive", onClick } = format

    let click
    if (onClick)
      click = () => {
        let display_modal = (item, parent) => {
          this.setState({
            modalOpen: true,
            modalData: item,
            modalParent: parent,
          })
        }
        onClick(item, display_modal)
      }


    return (
      <Button color={color} onClick={click}>
        {caption}
      </Button>
    )
  }

  arrayOfOne(format, item) {
    let { color, format: print } = format
    let text = print(item)

    let click
    if (format.onClick)
      click = () => {
        let display_modal = (clicked_item, parent) => {
          this.setState({
            modalOpen: true,
            modalData: clicked_item,
            modalParent: parent
          })
        }
        format.onClick(item, display_modal)
      }

    return (
      <ArrayComponent
        onClick={click}
        text={text}
        color={color}
      />
    )
  }

  array(format, item) {
    let items = format.array ? item[format.array] : [item]

    // Custom hide element
    if (format.visible) {
      if (!format.visible(item))
        return null
    }

    // Hide element if it has no length
    if (!items || items.length === 0)
      return null

    let { color, field, format: print } = format

    let key = 0
    let comps = items.map(child => {
      let text = child
      if (field) text = text[field]

      if (print) text = print(text)

      let click
      if (format.onClick)
        click = () => {
          let display_modal = (clicked_item, parent) => {
            this.setState({
              modalOpen: true,
              modalData: clicked_item,
              modalParent: parent
            })
          }
          // child is the array item.  Item is the parent if it exists
          format.onClick(child, display_modal, item)
        }

      key++
      return (
        <ArrayComponent
          onClick={click}
          key={`item_${key}`}
          text={text}
          color={color}
        />
      )
    })
    return (<List> {comps} </List>)
  }

  modal_link(format, item) {
    return (
      <span
        onClick={() => {
          // Show Modal
          this.setState({
            modalOpen: true,
            modalData: item,
          })
        }}
        style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}
      >
        {item[format.url]}
      </span>
    )
  }

  boolean(format, item) {
    let value = item[format.value]

    if (value)
      return <Icon name='check square' size="large" />
    return <Icon name='square outline' size="large" />
  }

  custom(format, item) {
    return format.format(item)
  }

  cell(format, item) {
    switch (typeof (format)) {
      case "string":
        return item[format]
      case "object": {
        switch (format.type) {
          case "preformat": return this.preformat(format, item)
          case "link:modal":
            return this.modal_link(format, item)
          case "link:email": return this.email_link(format, item)
          case "date": return this.date(format, item)
          case "button": return this.button(format, item)
          case "array": return this.array(format, item)
          case "array:one": return this.arrayOfOne(format, item)
          case "boolean": return this.boolean(format, item)
          case "custom": return this.custom(format, item)
          default: throw Error(`Cell ${format.type} type not found`)
        }
      }
      default:
        return ""
    }
  }

  newButton() {
    if (this.props.form.newButton === false)
      return undefined

    // Create the new button
    return (
      <Button color="green" onClick={() => this.setState({ modalOpen: true, modalData: null })}>
        New
        </Button>
    )
  }

  search() {
    if (!this.props.onSearch)
      return undefined

    return (
      <Search search={search => this.props.onSearch(search)} />
    )
  }

  pagination() {
    if (!this.props.paginate)
      return undefined

    let { pages, current_page, onClick } = this.props.paginate

    return (
      <Table.Row>
        <Table.HeaderCell colSpan='8'>
          <Pagination
            pages={pages}
            current_page={current_page}
            onClick={onClick}
          />
        </Table.HeaderCell>
      </Table.Row>
    )
  }

    handleFilterChange = (e, { value }) => {
        let {filter_values} = this.state
        filter_values = filter_values.map( (_, index)=>index===value );
        this.setState({ filter_values })
        this.props.filter_callback(filter_values)
    }

    render() {
        let { header, body=[], body_format, title, filter, form } = this.props
        let { error_messages = [], success_messages = [] } = this.props

        header = header.map(h => <Table.HeaderCell key={h}>{h}</Table.HeaderCell>)

        // Create filter
        if (filter) {
            let checkboxes = filter.map( (item, index)=>{
                return <Checkbox
                    label={ item.name }
                    name={ item.name }
                    key={ item.key }
                    value={ index }
                    checked={ this.state.filter_values[index] }
                    onChange={ this.handleFilterChange }
                />
            })
            filter = <Container>Filter on:<br/> {checkboxes}</Container>
        }
        // Create Rows
        let row_key = 0;
        // Loop through body to create rows
        body = body.map(row => {
            // init row key index to zero
            row_key++

            // init key index
            let col_key = 0
            // Create each cell
            let rows = body_format.map(r => {
                col_key++
                let cell = this.cell(r, row)
                return <Table.Cell key={`${row_key}_${col_key}`}> {cell} </Table.Cell>
            })

            return (
                <Table.Row key={row_key}>
                {rows}
                </Table.Row>
            )
        })

        // Create the new button
        let new_button = this.newButton()

        // Create the search
        let search_bar = this.search()
        let pagination = this.pagination()

        let editing = this.state.modalData != null

        let new_modal = <ModalComponent
            display={this.state.modalOpen}
            editItem={this.state.modalData}
            parentItem={this.state.modalParent}
            onClose={() => { this.setState({ modalOpen: false }); this.props.form.onClose() }}
            onClick={(results, item, parent) => this.props.form.onSave(results, item, parent)}
            saveLabel={editing ? 'Save' : 'Create'}
            dropdowns={this.props.dropdowns}
            form={form}
            error_messages={error_messages}
            success_messages={success_messages}
        />

        return (
            <div>
                <div>&nbsp;{this.state.modalOpen ? new_modal : ''}</div>

                <h1>{title}</h1>
                  <span>{new_button}</span>
                  <span>{search_bar}</span>
                  {filter}

                  <Table celled striped>
                      <Table.Header>
                          <Table.Row>
                          {header}
                          </Table.Row>
                      </Table.Header>

                      <Table.Body>
                          {body}
                      </Table.Body>

                      <Table.Footer>
                          {pagination}
                      </Table.Footer>

                  </Table>
            </div>
        )
    }
}
