
import React, { useState, useEffect, useContext } from 'react';
import TableComponent from '../component/TableComponent'
import { save_boilerplate } from '../lib/common'
import { realm_call } from '../lib/http'
import LoginContext from '../LoginContext'

export let program_form = ({ onSave, onClose, showSave }) => ({
  header: {
    icon: 'road',
    content: 'Program'
  },
  inputs: [
    { name: "Name", value: '', property: '_id' },
    { name: "Precode", value: '', property: 'precode' },
    { name: "Postcode", value: '', property: 'postcode' },
  ],
  showSave,
  onSave: (results, item) => onSave(results, item),
  onClose: () => onClose(),
})

const validate = (properties) => {
  const ret = [];

  if (properties._id.length === 0) { ret.push("Name can not be empty") };
  if (properties.precode.length === 0) { ret.push("Precode can not be empty") };
  if (properties.postcode.length === 0) { ret.push("Postcode can not be empty") };

  return ret;
};

export default props => {
  let [programs, setPrograms] = useState([])
  let [error_messages, setErrors] = useState([])
  let [success_messages, setSuccesses]= useState([])
  let { token } = useContext(LoginContext).login

  async function fetchPrograms () {
    let json = await realm_call({
      token, function: "read", args: [ "programs"]
    })

    setPrograms(json.programs)
  }

  let saveCallback = async (results, edit_item) =>  {
    save_boilerplate({
        token,
        results,
        edit_item,
        setErrors,
        setSuccesses,
        state: true,
        all: await fetchPrograms(),
        validate,
        model_name: 'program',
        url: 'programs',
    })
  }

  useEffect( () => {
    fetchPrograms()
    // eslint-disable-next-line 
  }, []);

  return (
    <TableComponent
      title="Program"
      header={['Name', 'Precode', 'Postcode']}
      body={programs}
      error_messages={error_messages}
      success_messages={success_messages}
      body_format={[
        {
          type: 'link:modal',
          url: '_id',
        },
        'precode',
        'postcode',
      ]}
      form={
        program_form({
          showSave: true,
          onSave: saveCallback,
          onClose: ()=>{ setErrors([]); setSuccesses([]) }
        })
      }
    />
  )
}
