import React, {useState} from 'react';
import {
        Container,
        Button,
        Form,
        Message,
    } from 'semantic-ui-react'
import { login_call } from './lib/http'

const loginLogic = async ({username, password, setError, setLogin}) => {
    // let  json = await realm_call({
    //   function: "login", args: [{email: username, password}]
    // })

    let json = await login_call(username, password)

    if (json.user && json.dealer && json.token) {
        // Save user and token globally
        setLogin(json)
        return true;
    }
    return false;
}

export default props => {
    let [error, setError] = useState(undefined)
    let [username, setUsername] = useState('')
    let [password, setPassword] = useState('')

    let {setLogin} = props

    let loginClick = async ()=>{
    let result = await loginLogic({username, password, setError, setLogin})
        // clear password after login attempt
        setPassword('')
        if (result) {
            // clear username when logged in
            setUsername('')
        } else {
            setError('Login Failed')
            setTimeout( ()=>{
                setError(undefined)
            }, 3*1000)
        }

    }

    return (
        <Container>
            <div>&nbsp;</div>
            <Form error={error!==undefined}>
                <Form.Input label='Username'
                    onChange={ (e, {value})=>setUsername(value)}
                    value={username}
                />
                <Form.Input label='Password'
                    type='password'
                    onChange={ (e, {value})=>setPassword(value)}
                    value={password}
                />
                <Message
                    error
                    content={error}
                />
                <Button onClick={loginClick}>Submit</Button>
            </Form>
        </Container>

    )
}
